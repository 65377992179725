<template>
  <div style="height: 90%">

    <div class="">
      <div
        class="card"
      >
        <a
          :id="idConfig.viewBookmark"
          style="display: none"
        >Current view</a>
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color: #F3F2F7"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              style="width: 100%"
            >
              <span style="color: #5E5873; font-weight: 600">{{ name }}</span>
              <div class="d-flex align-items-center">
                <div>
                  <input
                    :id="idConfig.pageNumber"
                    type="number"
                    name="pageNumber"
                    style="width: 40px"
                  >
                </div>

                <span class="divider" />
                <span :id="idConfig.numPages" />
                <span class="divider"> of pages</span>
              </div>
              <div class="d-flex">
                <button
                  :id="idConfig.zoomOut"
                  type="button"
                  class="action-btn"
                  title="Zoom out"
                >
                  <span class="mdi mdi-minus-circle-outline" />
                </button>
                <span class="divider" />
                <button
                  :id="idConfig.zoomIn"
                  type="button"
                  class="action-btn btn btn primary"
                  title="Zoom in"
                >
                  <span class="mdi mdi-plus-circle-outline" />
                </button>
              </div>
              <!-- <div>
                <div class="btn btn-success">
                  Transform
                </div>
              </div> -->
              <div class="d-flex">
                <feather-icon
                  :id="idConfig.download"
                  icon="DownloadIcon"
                  type="button"
                  title="Download"
                  class="mr-50"
                  size="28"
                />
                <feather-icon
                  :id="idConfig.print"
                  icon="PrinterIcon"
                  class="mr-50"
                  size="28"
                />
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="mr-50"
                  size="28"
                />
              </div>
            </div>
          </div>
          <!-- :pdf="`${publicPath}pnlc.pdf`" -->
          <div class="modal-body">
            <!-- :pdf="`${publicPath}${pdf}`" -->
            <vue-pdf-app
              :pdf="file"
              :config="{ toolbar: false }"
              :id-config="idConfig"
              style="height: 68vh; position: relative"
              theme="light"
            >
              <template #viewer-header>
                <div class="viewer-header">
                  <!-- <button
                    :id="idConfig.openFile"
                    class="action-btn"
                    type="button"
                    title="Open file"
                  >
                    <span class="mdi mdi-file-upload-outline" />
                  </button> -->
                  <span class="divider" />
                  <span class="divider" />
                  <button
                    :id="idConfig.presentationMode"
                    class="action-btn"
                    type="button"
                    title="Presentation mode"
                  >
                    <span class="mdi mdi-presentation" />
                  </button>
                  <span class="divider" />
                  <button
                    :id="idConfig.toggleFindbar"
                    class="action-btn"
                    type="button"
                    title="Toggle findbar"
                  >
                    <span class="mdi mdi-magnify" />
                  </button>
                </div>
              </template>
              <template #viewer-prepend="{ isSidebarHidden }">
                <div class="viewer-prepend">
                  <button
                    :id="idConfig.firstPage"
                    class="action-btn"
                    type="button"
                    title="First page"
                  >
                    <span class="mdi mdi-skip-backward" />
                  </button>
                  <span class="v-divider" />
                  <button
                    :id="idConfig.previousPage"
                    class="action-btn"
                    type="button"
                    title="Previous page"
                  >
                    <span class="mdi mdi-skip-previous" />
                  </button>
                  <span class="v-divider" />
                  <button
                    :id="idConfig.nextPage"
                    class="action-btn"
                    type="button"
                    title="Next page"
                  >
                    <span class="mdi mdi-skip-next" />
                  </button>
                  <span class="v-divider" />
                  <button
                    :id="idConfig.lastPage"
                    class="action-btn"
                    type="button"
                    title="Last page"
                  >
                    <span class="mdi mdi-skip-forward" />
                  </button>
                  <!-- don't use v-if here -->
                  <!-- otherwise got an error -->
                  <span class="v-divider" />
                  <button
                    :id="idConfig.sidebarToggle"
                    class="action-btn"
                    type="button"
                    title="Toggle sidebar"
                  >
                    <span class="mdi mdi-page-layout-sidebar-left" />
                  </button>
                  <span class="v-divider" />
                  <div v-show="!isSidebarHidden">
                    <button
                      :id="idConfig.viewThumbnail"
                      class="action-btn"
                      type="button"
                      title="Thumbnail view"
                    >
                      <span class="mdi mdi-file-image" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.viewOutline"
                      class="action-btn"
                      type="button"
                      title="Outline view"
                    >
                      <span class="mdi mdi-table-of-contents" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.viewAttachments"
                      class="action-btn"
                      type="button"
                      title="Attachments view"
                    >
                      <span class="mdi mdi-paperclip" />
                    </button>
                  </div>
                </div>
              </template>
              <template #viewer-footer="{ isFindbarHidden }">
                <div class="viewer-footer">
                  <div>
                    <button
                      :id="idConfig.cursorHandTool"
                      class="action-btn"
                      type="button"
                      title="Hand tool"
                    >
                      <span class="mdi mdi-hand-right" />
                    </button>
                    <!-- <span class="v-divider" /> -->
                    <button
                      :id="idConfig.cursorSelectTool"
                      class="action-btn"
                      type="button"
                      title="Select tool"
                    >
                      <span class="mdi mdi-cursor-text" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.documentProperties"
                      class="action-btn"
                      type="button"
                      title="Document properties"
                    >
                      <span class="mdi mdi-alert-circle-outline" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.scrollVertical"
                      class="action-btn"
                      type="button"
                      title="Scroll vertical"
                    >
                      <span class="mdi mdi-arrow-expand-vertical" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.scrollWrapped"
                      class="action-btn"
                      type="button"
                      title="Scroll wrapped"
                    >
                      <span class="mdi mdi-view-dashboard" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.scrollHorizontal"
                      class="action-btn"
                      type="button"
                      title="Scroll horizontal"
                    >
                      <span class="mdi mdi-arrow-expand-horizontal" />
                    </button>
                  </div>
                  <span class="v-divider" />
                  <div>
                    <button
                      :id="idConfig.pageRotateCcw"
                      class="action-btn"
                      type="button"
                      title="Rotate counterclockwise"
                    >
                      <span class="mdi mdi-rotate-left" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.pageRotateCw"
                      class="action-btn"
                      type="button"
                      title="Rotate clockwise"
                    >
                      <span class="mdi mdi-rotate-right" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.spreadEven"
                      class="action-btn"
                      type="button"
                      title="Spread even"
                    >
                      <span class="mdi mdi-numeric-2-circle-outline" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.spreadNone"
                      class="action-btn"
                      type="button"
                      title="Spread none"
                    >
                      <span class="mdi mdi-view-agenda-outline" />
                    </button>
                    <span class="v-divider" />
                    <button
                      :id="idConfig.spreadOdd"
                      class="action-btn"
                      type="button"
                      title="Spread odd"
                    >
                      <span class="mdi mdi-numeric-1-circle-outline" />
                    </button>
                  </div>
                  <div
                    v-show="!isFindbarHidden"
                    :id="idConfig.findbar"
                    class=" vue-pdf-app-findbar"
                  >
                    <input
                      :id="idConfig.findInput"
                      type="text"
                    >
                    <!-- <span class="divider" /> -->
                    <button
                      :id="idConfig.findPrevious"
                      class="action-btn"
                      title="Find previous"
                    >
                      <span class="mdi mdi-arrow-left-thick" />
                    </button>
                    <!-- <span class="divider" /> -->
                    <button
                      :id="idConfig.findNext"
                      class="action-btn"
                      title="Find next"
                    >
                      <span class="mdi mdi-arrow-right-thick" />
                    </button>
                    <!-- <span class="divider" /> -->
                    <input
                      :id="idConfig.findHighlightAll"
                      type="checkbox"
                    >
                    <label
                      :for="idConfig.findHighlightAll"
                    >Highlight all</label>
                    <!-- <span class="divider" /> -->
                    <input
                      :id="idConfig.findMatchCase"
                      type="checkbox"
                    >
                    <label :for="idConfig.findMatchCase">Match case</label>
                    <!-- <span class="divider" /> -->
                    <input
                      :id="idConfig.findEntireWord"
                      type="checkbox"
                    >
                    <label :for="idConfig.findEntireWord">Entire word</label>
                    <span class="divider" />
                    <span :id="idConfig.findMessage" />
                    <span class="divider" />
                    <span :id="idConfig.findResultsCount" />
                  </div>
                </div>
              </template>
            </vue-pdf-app>
          </div>
        </div>
      </div>
      <!-- <div
        class="p-2 bg-white"
        style="width: 30%"
      >
        <span style="font-size: 18px; font-weight: 500; color: #5E5873">Data Scrape Preview </span>
        <div
          style="border: 1px solid #5E5873;"
          class="mt-1 d-flex col-12 rounded justify-content-between align-items-center"
        >
          <div
            style="border-right: 1px solid #5E5873 "
            class="col"
            @click="isInfos()"
          >
            <div class="my-1 text-center">
              Info
            </div>
          </div>
          <div
            v-b-modal.modal-1
            class="col"
            @click="isJsons()"
          >
            <div class="my-1 text-center">
              JSON
            </div>
          </div>
          <div
            style="border-left: 1px solid #5E5873; overflow: auto"
            class="col"
            @click="isLogs()"
          >
            <div
              class="my-1 text-center"
              style="overflow: auto"
            >
              Log
            </div>
          </div>
        </div>
        <hr>
        <div
          v-if="isInfo"
          class="pl-3 pt-1 pr-2 pb-2"
        >
          <div class="info d-flex justify-content-between pb-2">
            <strong>Scrap Data Type</strong>
            <span style="border-bottom: 1px solid #6E6B7B">Table View</span>
          </div>
          <p>Rest Area</p>
          <p>TNB Compound</p>
          <p>Genset</p>
          <p>Warehouse</p>
          <p>PPE Storage Area</p>
          <p>Structure Steel Yard</p>
          <p>Fabrication Yard</p>
          <p>TNB Sub Station</p>
          <p>Concrete Test Area</p>
          <p>Curing Tank</p>
          <p>Septic Tank</p>
          <p>Perimeter Fence</p>
          <hr>
          <div class="pt-2 pb-2">
            <strong>Miscellaneous</strong>
          </div>
          <p>
            Scraping Method
          </p>
          <b-form-select
            v-model="selected"
            label="title"
            :options="option"
          />
          <p class="pt-1">
            OCR Engine
          </p>
          <b-form-select
            v-model="selected1"
            label="title"
            :options="option"
          />
          <p class="pt-1">
            Languages
          </p>
          <b-form-select
            v-model="selected2"
            label="title"
            :options="option"
          />
          <p class="pt-1">
            Characters
          </p>
          <b-form-select
            v-model="selected3"
            label="title"
            :options="option"
          />
          <p class="pt-1">
            Scale
          </p>
          <b-form-select
            v-model="selected4"
            label="title"
            :options="option"
            class="pb-2"
          />
          <b-form-checkbox
            v-for="option in option"
            :key="option.value"
            v-model="selected"
            :value="option.value"
            name="flavour-4a"
            class="pb-2"
          >
            {{ option.text }}
          </b-form-checkbox>
        </div>
        <div
          v-else-if="isLog"
          class="pl-2 pt-2"
        >
          <app-timeline>
            <app-timeline-item variant="success">

              <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
                <h6>Info</h6>
              </div>
              <p>Executing: OCRExtractRelative</p>
              <div><span class="text-muted">12 min ago</span></div>
            </app-timeline-item>
            <app-timeline-item variant="warning">

              <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
                <h6>Info</h6>
              </div>
              <p>OCR (eng) started (2.4KB)</p>
              <div><span class="text-muted">12 min ago</span></div>
            </app-timeline-item>
            <app-timeline-item variant="info">

              <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
                <h6>Info</h6>
              </div>
              <p>OCR result received (0.4s)</p>
              <div><span class="text-muted">12 min ago</span></div>
            </app-timeline-item>
            <app-timeline-item variant="danger">

              <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
                <h6>Info</h6>
              </div>
              <p>OCR result received (0.4s)</p>
              <div><span class="text-muted">12 min ago</span></div>
            </app-timeline-item>
            <app-timeline-item variant="dark">

              <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
                <h6>Macro Completed</h6>
              </div>
              <p>Runtime 6.45s</p>
              <div><span class="text-muted">12 min ago</span></div>
            </app-timeline-item>
          </app-timeline>
        </div>
        <div class="modal">
          <b-modal
            id="modal-1"
            :hide-footer="true"
          >
            <b-table
              responsive="sm"
              :items="items"
            >
              <template #cell(priority_type)="data">
                <b-badge variant="light-warning">
                  {{ data.item.priority_type }}
                </b-badge>
              </template>
            </b-table>
          </b-modal>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style>
.viewer-prepend {
    top: 300px!important;

}
.viewer-header{
  display: none!important;
}
</style>

<script>
import "@mdi/font/css/materialdesignicons.css"
// import {
//   BTable, BBadge,
// } from "bootstrap-vue"

export default {
  components: {
    // BTable,
    // BBadge,
    // AppTimeline,
    // AppTimelineItem,
    // BFormCheckbox,
    // BFormSelect,
  },
  props: {
    file: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInfo: true,
      isLog: false,
      isJson: false,
      publicPath: process.env.BASE_URL,
      idConfig: {
        cursorHandTool: "vuePdfAppCursorHandTool",
        cursorSelectTool: "vuePdfAppCursorSelectTool",
        documentProperties: "vuePdfAppDocumentProperties",
        download: "vuePdfAppDownload",
        findbar: "vuePdfAppFindbar",
        findEntireWord: "vuePdfAppFindEntireWord",
        findHighlightAll: "vuePdfAppFindHighlightAll",
        findInput: "vuePdfAppFindInput",
        findMessage: "vuePdfAppFindMessage",
        findMatchCase: "vuePdfAppFindMatchCase",
        findNext: "vuePdfAppFindNext",
        findPrevious: "vuePdfAppFindPrevious",
        findResultsCount: "vuePdfAppFindResultsCount",
        firstPage: "vuePdfAppFirstPage",
        lastPage: "vuePdfAppLastPage",
        nextPage: "vuePdfAppNextPage",
        numPages: "vuePdfAppNumPages",
        // openFile: "vuePdfAppOpenFile",
        pageNumber: "vuePdfAppPageNumber",
        pageRotateCcw: "vuePdfAppPageRotateCcw",
        pageRotateCw: "vuePdfAppPageRotateCw",
        presentationMode: "vuePdfAppPresentationMode",
        previousPage: "vuePdfAppPreviousPage",
        print: "vuePdfAppPrint",
        scrollHorizontal: "vuePdfAppScrollHorizontal",
        scrollVertical: "vuePdfAppScrollVertical",
        scrollWrapped: "vuePdfAppScrollWrapped",
        sidebarToggle: "vuePdfAppSidebarToggle",
        spreadEven: "vuePdfAppSpreadEven",
        spreadNone: "vuePdfAppSpreadNone",
        spreadOdd: "vuePdfAppSpreadOdd",
        toggleFindbar: "vuePdfAppToggleFindbar",
        viewAttachments: "vuePdfAppViewAttachments",
        viewBookmark: "vuePdfAppViewBookmark",
        viewOutline: "vuePdfAppViewOutline",
        viewThumbnail: "vuePdfAppViewThumbnail",
        zoomIn: "vuePdfAppZoomIn",
        zoomOut: "vuePdfAppZoomOut",
      },
      pdf: "files/file_manager_sample/pnlc.pdf",
      option: [
        { text: 'Invert', value: 'Invert' },
        { text: 'Get Words Info', value: 'Get Words Info' },
      ],
      selected: { title: 'OCR' },
      selected1: { title: 'Google OCR' },
      selected2: { title: 'Eng' },
      selected3: { title: 'Any Character' },
      selected4: { title: '2' },
      items: [
        {
          temporary_building: 'Genset', priority_type: 'String',
        },
        {
          temporary_building: 'Schedule Waste', priority_type: 'String',
        },
        {
          temporary_building: 'Structure Steel Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Fabrication Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Foldable Gate', priority_type: 'String',
        },
        {
          temporary_building: 'TNB Sub Station', priority_type: 'String',
        },
        {
          temporary_building: 'Septic Tank', priority_type: 'String',
        },
      ],
    }
  },
  methods: {
    isInfos(){
      this.isInfo = true
      this.isLog = false
    },
    isJsons(){
      this.isJson = false
      this.isInfo = false
      this.isLog = false
    },
    isLogs(){
      this.isLog = true
      this.isInfo = false
    },
  },
}
</script>

<style lang="scss" scoped>
.viewer-header {
  margin-top: 10px;
  position: relative;
  z-index: 9999;
}

.viewer-prepend {
  position: absolute;
  z-index: 99999;
  right: 20px;
  bottom: 0;
  top: 40px;
  // width: 80px;
}

.viewer-header .action-btn,
.viewer-prepend .action-btn,
.viewer-footer .action-btn {
  background: white;
  opacity: 0.8;
}

.viewer-footer {
  position: absolute;
  top: 150px;
  width: 10px;
  right: 0;
  left: 15px;
}

.action-btn {
  background: transparent;
  padding: 0px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: black;
  border: none;
  outline: none;

  &[disabled] {
    cursor: default;
    background: gray;
  }
  & > span {
    font-size: 28px;
  }
}

.action-bar {
  margin-bottom: 5px;
}

.divider,
.v-divider {
  padding: 3px;
}

.v-divider {
  display: block;
}

.vue-pdf-app-findbar {
  display: inline-block;
  background: white;
  opacity: 0.8;
  padding: 0 3px;
  border-radius: 3px;
}
</style>
